import ApiService from "@/core/services/api.service";

export default {

    // -----API VERIFIKASI DATA AFKIR DINI------
    getAfkirDiniAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/data/afkir-dini/all", { params: credentials })
    },
    getAfkirDiniSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/data/afkir-dini/edit", { params: credentials })
    },
    verifAfkirDini(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/data/afkir-dini", credentials)
    },
}